import { PositionalAudio } from "@react-three/drei";
import React, { useState } from "react";
import { Vector3 } from "three";
import { ControlOptions, useControl } from "react-three-gui";
// eslint-disable-next-line
import { PositionalAudioProps, useFrame } from "react-three-fiber";
import { useSpring } from "react-spring";

// Spatial Audio Built Off https://drei.pmndrs.vercel.app/?path=/story/abstractions-positionalaudio--positional-audio-scene-st

const Soundscape: React.FC<{
  soundStateRef: React.MutableRefObject<boolean>;
}> = ({ soundStateRef }) => {
  // const positionalAudioRef = useRef<PositionalAudioProps>();
  const [soundEnabled, setSoundEnabled] = useState(false);

  //GUI Control of XZ location of sources
  const common = (): ControlOptions => ({
    type: "xypad",
    distance: 50,
    scrub: true,
  });

  // used to dtermine falloff of audio source using "distance" method
  const distance = 0.0;
  // const rolloffFactor = 1;

  const dist = (): ControlOptions => ({
    type: "number",
    min: 0,
    max: 1,
    value: distance,
    scrub: true,
  });

  // const a1 = useControl("Archive", common());
  // const b1 = useControl("Archive Dist", dist());
  // const a2 = useControl("Bill", common());
  // const b2 = useControl("Bill Dist", dist());
  // const a3 = useControl("Philip", common());
  // const b3 = useControl("Philip Dist", dist());
  const a4 = useControl("Dog Barking", common());
  const b4 = useControl("Dog Dist", dist());
  const a5 = useControl("Chimes", common());
  const b5 = useControl("Chimes Dist", dist());
  const a6 = useControl("Thrasher", common());
  const b6 = useControl("Thrasher", dist());
  const a7 = useControl("Sign", common());
  const b7 = useControl("Sign Dist", dist());

  const args = React.useMemo(
    () => [
      // {
      //   position: new Vector3(a1.x + 6.0687, a1.y + 9.3209, 2.415),
      //   url: "/assets/audio/film/archival.mp3",
      //   distance: b1,
      // },
      // {
      //   position: new Vector3(a2.x - 26.648, a2.y - 6.1218, 1.47),
      //   url: "/assets/audio/film/bill.mp3",
      //   distance: b2,
      // },
      // {
      //   position: new Vector3(a3.x + 1.933372, a3.y - 9.7908, 2.5761),
      //   url: "/assets/audio/film/philip.mp3",
      //   distance: b3,
      // },
      {
        position: new Vector3(a4.x + 23.5, a4.y + 7, 0),
        url: "/assets/audio/dogBarking.mp3",
        distance: b4,
      },
      {
        position: new Vector3(a5.x + 7, a5.y + 9, 0),
        url: "/assets/audio/chimes.mp3",
        distance: b5,
      },
      {
        position: new Vector3(a6.x + 3.1, a6.y + 13.5, 0),
        url: "/assets/audio/thrasher.mp3",
        distance: b6,
      },
      {
        position: new Vector3(a7.x - 23, a7.y - 5.5, 0),
        url: "/assets/audio/creakingSign.mp3",
        distance: b7,
      },
    ],
    [a4, a5, a6, a7, b4, b5, b6, b7]
  );

  const audioProps = useSpring({ distance: soundEnabled ? 0 : 0.22 });

  useFrame(() => {
    setSoundEnabled(soundStateRef.current);
    // if (positionalAudioRef.current) {
    //   let p = positionalAudioRef.current;
    //   //@ts-ignore
    //   p.setRefDistance(10);
    // }
  });

  // useEffect(() => {
  //   if (soundEnabled && positionalAudioRef.current) {
  //     let p = positionalAudioRef.current;
  //     console.log(p);
  //     //@ts-ignore
  //     p.setRolloffFactor(1);
  //     //@ts-ignore
  //     console.log(p.isPlaying);
  //     if (!p.isPlaying) {
  //       //@ts-ignore
  //       p.play();
  //     }
  //   }
  // }, [soundEnabled]);

  return (
    <group position={[0, 0, 0]}>
      {soundEnabled &&
        args.map(({ position, url, distance }, index) => (
          <mesh key={`0${index}`} position={position}>
            {/* <sphereBufferGeometry attach="geometry" args={[0.5]} />
            <meshBasicMaterial
              wireframe
              attach="material"
              color="white"
              opacity={0}
            /> */}
            <PositionalAudio
              distance={audioProps.distance.getValue() as number}
              url={url}
            />
          </mesh>
        ))}
    </group>
  );
};

export default Soundscape;
