import React from "react";
import {
  EffectComposer,
  HueSaturation,
  Noise,
  Vignette,
  BrightnessContrast,
  Bloom,
} from "@react-three/postprocessing";

const PostFx: React.FC = () => {
  return (
    <EffectComposer>
      <BrightnessContrast brightness={-0.02} contrast={0.02} />
      <Bloom
        luminanceThreshold={0.4}
        luminanceSmoothing={0.85}
        intensity={0.6}
      />
      <Noise opacity={0.04} />
      <Vignette eskil={false} offset={0.05} darkness={0.9} />
      <HueSaturation />

      {/* <SSAO     
        // blendFunction={BlendFunction.Multiply} // blend mode
        samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
        rings={4} // amount of rings in the occlusion sampling pattern
        distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
        distanceFalloff={0.0} // distance falloff. min: 0, max: 1
        rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
        rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
        luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
        radius={20} // occlusion sampling radius
        scale={0.5} // scale of the ambient occlusion
        bias={0.5} // occlusion bias 
        /> */}
      {/* <DepthOfField focusDistance={focusDist} focalLength={focalLength} bokehScale={bokehScale} height={dofHeight} />
      <Bloom luminanceThreshold={lumThresh} luminanceSmoothing={lumSmooth} height={bloomHeight} /> */}
      {/* <Noise opacity={noiseOpac} /> */}
      {/* <Vignette eskil={false} offset={0.1} darkness={1.1} /> */}
    </EffectComposer>
  );
};

export default PostFx;
