import React, { Suspense, useCallback, useRef } from "react";
// import { Canvas } from "react-three-fiber";
import { Loader, Preload } from "@react-three/drei";
// import { Stats } from "@react-three/drei";
import { Controls } from "react-three-gui";
import { Global } from "./styles";
import VideoPlane from "./components/VideoPlane";
import Cloud from "./components/Cloud";
import Starscape from "./components/Starscape";
import Soundscape from "./components/Audio";
import CamPath from "./components/CamPath";
// import PointerLock from "./components/PointerLock";
// import { Html } from "@react-three/drei";
import PostFx from "./components/PostFx";
// import { Euler, Vector3 } from "three";
// import { WebGLRenderer } from "three";
// import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
// import { LuminosityShader } from "three/examples/jsm/shaders/LuminosityShader.js";
import Overlay from "./components/Overlay";
import { useSpring } from "react-spring";
import * as easings from "d3-ease";
// import { Canvas } from "react-three-fiber";

// const renderer = new WebGLRenderer({
//   powerPreference: "high-performance",
//   antialias: false,
//   stencil: false,
//   depth: false,
// });

const cloud1Url = "/assets/pointclouds/TP-561k-Random-Binary/";
const video1Url = "/assets/video/archive.mp4";
const video2Url = "/assets/video/philip.mp4";
const video3Url = "/assets/video/bill.mp4";

export default function App() {
  const soundStateRef = useRef(false);
  const sceneCamRef = useRef(0);
  const handleDiscover = useCallback(() => {
    soundStateRef.current = !soundStateRef.current;
    console.log(soundStateRef.current);
  }, []);

  const [{ pos, rotX, rotZ }, setProps] = useSpring<{ pos: number, rotX: number, rotZ: number }>(() => ({
    pos: 39,
    rotX: 0,
    rotZ: 0,
    config: {
      mass: 1,
      tension: 30,
      friction: 15,
      precision: 0.01,
      clamp: true,
      velocity: 0,
      easing: easings.easeCubicInOut,
    },
    // config: {
    //   mass: 4,
    //   tension: 10,
    //   clamp: true,
    //   friction: 10,
    //   velocity: 0,
    // },
  }));

  return (
    <>
      <Global />
      <Controls.Provider>
        <Controls.Canvas>
          <Suspense fallback={null}>
            <VideoPlane
              name="Archival Video"
              url={video1Url}
              ratio={[4, 3]}
              index={0}
              soundStateRef={soundStateRef}
            />
            <VideoPlane
              name="Bill Video"
              url={video2Url}
              ratio={[5.33, 3]}
              index={1}
              soundStateRef={soundStateRef}
            />
            <VideoPlane
              name="Philip Video"
              url={video3Url}
              ratio={[5.33, 3]}
              index={2}
              soundStateRef={soundStateRef}
            />
            <Cloud url={cloud1Url} />
            <CamPath pos={pos} rotX={rotX} rotZ={rotZ} setProps={setProps} sceneCamRef={sceneCamRef} />
            <Soundscape soundStateRef={soundStateRef} />
            <Preload all />
          </Suspense>
          <Starscape />
          {/* <TrackballControls /> */}
          {/* <Stats /> */}
          <PostFx />
        </Controls.Canvas>
        <Loader />
        {/* <Controls
          title="TorC Viewer"
          collapsed={true}
          anchor="top_left"
          defaultClosedGroups={[
            "Cloud",
            "Archival",
            "Bill",
            "Philip",
            "Cam Path",
          ]}
        /> */}
      </Controls.Provider>
      <Overlay
        handleDiscover={handleDiscover}
        setProps={setProps}
        sceneCamRef={sceneCamRef}
      />
    </>
  );
}

// const GUI: React.FC = () => {
//   return (
//     <Html
//       prepend // Project content behind the canvas (default: false)
//       center // Adds a -50%/-50% css transform (default: false)
//       fullscreen // Aligns to the upper-left corner, fills the screen (default:false)
//       scaleFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera.
//       zIndexRange={[100, 0]} // Z-order range (default=[16777271, 0])
//       // calculatePosition={(el: Object3D, camera: Camera, size: { width: number; height: number }) => number[]} // Override default positioning function. May be removed in the future (default=undefined)
//       // {...groupProps} // All THREE.Group props are valid
//       // {...divProps} // All HTMLDivElement props are valid
//     >
//       <h1 style={{ color: "white" }}>hello</h1>
//       <p>world</p>
//     </Html>
//   );
// };
