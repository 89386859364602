import { Plane } from "@react-three/drei";
import React from "react";
import { useRef, useState } from "react";
import { MeshProps, useFrame, useThree, Vector3 } from "react-three-fiber";
import { ControlOptions, useControl } from "react-three-gui";
import { DoubleSide, Material, Mesh } from "three";
import { isBrowser, isMobile } from "react-device-detect";

const VideoPlane: React.FC<
  MeshProps & {
    url: string;
    ratio: number[];
    index: number;
    soundStateRef: React.MutableRefObject<boolean>;
  }
> = ({ url, ratio, index, soundStateRef, ...props }) => {
  const mesh = useRef<Mesh>();
  const { camera } = useThree();
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = url;
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    if (isBrowser) {
      vid.autoplay = true;
    }
    if (isMobile) {
      vid.autoplay = false;
    }
    vid.play();
    return vid;
  });

  const common = (value = 0): ControlOptions => ({
    group: props.name,
    type: "number",
    value,
    min: -10,
    max: 10,
  });

  // create start positons, base rotations and uniform scalars for planes
  let startPositions: Array<any> = [];
  let baseRotations: Array<any> = [];
  let uScale: Array<any> = [];
  let uFade: Array<any> = [];

  // archival
  let originA: Vector3 = [6.0687 + 2.53, 9.3209 - 0.4, 2.415 - 0.6];
  let rotA: Vector3 = [0, 0.17, 0];
  let scaleA: number = 0.87;
  let fadeA: number = 14.5 + 1.5;

  // philip
  let originB: Vector3 = [0.033372 + 2.2, -7.2908 + 1.2, 2.5761 + 0.6];
  let rotB: Vector3 = [0, Math.PI / 4 - 2.56, 0];
  let scaleB: number = 2;
  let fadeB: number = 12.3 + 3;

  // bill
  let originC: Vector3 = [-26.648 - 9.53, -6.1218 - 0.6, 1.47 + 5.33];
  let rotC: Vector3 = [0.03, 3.37, 0];
  let scaleC: number = 4.33;
  let fadeC: number = 27 + 4.5;

  startPositions.push(originA, originB, originC);
  baseRotations.push(rotA, rotB, rotC);
  uScale.push(scaleA, scaleB, scaleC);
  uFade.push(fadeA, fadeB, fadeC);

  const posX = useControl("X", common(0));
  const posY = useControl("Y", common(0));
  const posZ = useControl("Z", common(0));
  const rotX = useControl("RotX", common(Math.PI / 2));
  const rotY = useControl("RotY", common(-Math.PI / 2));
  const rotZ = useControl("RotZ", common(0));
  const scale = useControl("Scale", common(0));
  // const fade = useControl("Fade distance", {
  //   group: props.name,
  //   type: "number",
  //   value: 15,
  //   min: 0,
  //   max: 50,
  // });

  useFrame(() => {
    if (mesh.current) {
      const dist = camera.position.distanceTo(mesh.current.position);
      const opacity = Math.min(1, Math.max(0, 1.3 - dist / uFade[index]));

      // audio for archival and philip
      if (index <= 1) {
        video.volume = Math.min(
          1,
          Math.max(0, 1.3 - dist / (uFade[index] + 1.25))
        );
        // audio for bill
      } else {
        video.volume = Math.min(
          1,
          Math.max(0, 1.3 - dist / (uFade[index] + 5))
        );
      }

      const material = mesh.current.material as Material;
      material.opacity = opacity;

      if (soundStateRef.current && video.muted) {
        video.muted = false;
        if (isMobile) {
          video.play();
        }
      }
      if (!soundStateRef.current) {
        video.muted = true;
      }
    }
  });

  return (
    <Plane
      position={[
        posX + startPositions[index][0],
        posY + startPositions[index][1],
        posZ + startPositions[index][2],
      ]}
      rotation={[
        rotX + baseRotations[index][0],
        rotY + baseRotations[index][1],
        rotZ + baseRotations[index][2],
      ]}
      scale={[
        ratio[0] * uScale[index] + scale,
        ratio[1] * uScale[index] + scale,
        0,
      ]}
      ref={mesh}
    >
      <meshBasicMaterial side={DoubleSide} transparent>
        <videoTexture attach="map" args={[video]} />
      </meshBasicMaterial>
    </Plane>
  );
};

export default VideoPlane;
