import React, { useEffect, useRef } from "react";
import { useFrame, useThree } from "react-three-fiber";
// import { ControlOptions, useControl } from "react-three-gui";
import { OpaqueInterpolation, SetUpdateFn } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import {
  Vector2,
  Vector3,
  CatmullRomCurve3,
  TubeGeometry,
  MeshBasicMaterial,
  DoubleSide,
  Mesh,
  PerspectiveCamera,
} from "three";
import { useGesture } from "react-use-gesture";
import { isBrowser, isMobile } from "react-device-detect";

// import { throttle } from "lodash";
// import { PointerLockControls, TrackballControls } from "@react-three/drei";

// bring in points from blender v2
const points = [
  [23.375654220581055, 53.6305046081543, 46.04884719848633],
  [23.385658264160156, 33.596961975097656, 41.074153900146484],
  [23.392152786254883, 27.00952911376953, 21.397132873535156],
  [23.393753051757812, 23.02826690673828, 9.446666717529297],
  [23.388456344604492, 18.573081970214844, 2.6541354656219482],
  [22.054500579833984, 10.990888595581055, 1.1467454433441162],
  [13.366748809814453, 9.176737785339355, 1.1467454433441162],
  [2.6889076232910156, -2.003364086151123, 1.1467454433441162],
  [-24.376544952392578, -5.7575883865356445, 2.951458692550659],
  [-41.92870330810547, -5.7575883865356445, 23.752975463867188],
  [-46.28922653198242, -5.7575883865356445, 26.884801864624023],
];

const blenderToThreeArray = (blenderVertexArray: number[][]): Vector3[] =>
  blenderVertexArray.map(([x, y, z]) => new Vector3(x, y, z));

const SceneCam: React.FC<{
  // vv should this ref be a number instead of a boolean? vv
  sceneCamRef: React.MutableRefObject<number>;
  pos: OpaqueInterpolation<number>;
  rotX: OpaqueInterpolation<number>;
  rotZ: OpaqueInterpolation<number>;
  setProps: SetUpdateFn<any>;
}> = ({ pos, rotX, rotZ, setProps, sceneCamRef }) => {
  const cameraRef = useRef<PerspectiveCamera>();

  const { setDefaultCamera, camera } = useThree();
  const vertexBuf = blenderToThreeArray(points);

  // curve from points
  const curvePath = new CatmullRomCurve3(vertexBuf);
  const radius = 0.005;

  const geometry = new TubeGeometry(curvePath, 10000, radius, 1, false);

  // apply material, add mesh
  const material = new MeshBasicMaterial({ color: "red", side: DoubleSide });
  const tubular = new Mesh(geometry, material);

  // scale used for scene camera path
  const scale = 1;

  useEffect(() => {
    const c = cameraRef.current;
    if (c) {
      setDefaultCamera(c);
    }
  });

  function moveCamera(pos: number) {
    let percentage = pos / 100;

    sceneCamRef.current = percentage;

    // add additional arc lengths to even out curve
    curvePath.arcLengthDivisions = 600;
    // console.table(curvePath.getLengths());

    var p1 = curvePath.getPointAt(percentage.valueOf() % 1);
    var p2 = curvePath.getPointAt((percentage.valueOf() + 0.001) % 1);

    // IMPORTANT Set Up Vector for Z Up Coords
    camera.up.set(0, 0, 1);

    camera.position.x = p1.x;
    camera.position.y = p1.y;
    camera.position.z = p1.z + 0.075;

    //lookAt is utility function for calculating angular rotation - resets object every time it is called
    camera.lookAt(p2.x, p2.y, p2.z);
  }

  // value mapping function
  const map = (value: number, x1: number, y1: number, x2: number, y2: number) =>
    ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

  // let xSmooth: Array<any> = [0, 0, 0, 0];

  // function filter(num: number, array: any[]) {
  //   array.splice(-1, 1);
  //   array.push(num);

  //   var total = 0;
  //   for (var i = 0; i < array.length; i++) {
  //     total += array[i];
  //   }
  //   var avg = total / array.length;
  //   return avg;
  // }

  function rotateCamera(mouse: Vector2) {
    const vert = map(mouse.y, -1, 1, 0.1, 1.1);
    // const horiz = filter(-mouse.x, xSmooth);

    if (isBrowser) {
      setProps({
        rotX: vert * (Math.PI / 4),
        rotZ: -mouse.x * (Math.PI / 4)
      });
    }

    camera.rotateOnAxis(new Vector3(1, 0, 0), rotX.getValue());
    camera.rotateOnWorldAxis(new Vector3(0, 0, 1), rotZ.getValue());
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setProps({
  //       pos: 50,
  //       config: {
  //         mass: 40,
  //         tension: 10,
  //         friction: 5,
  //         clamp: true,
  //         velocity: 0,
  //       },
  //     });
  //   }, 3000);
  // }, []);

  const speedLimit = 2.2;

  const bind = useGesture(
    {
      onWheel: (e) => {
        if(e.delta[1] == 0) return;
        
        // const dir = e.direction[1];
        setProps({ pos: pos.getValue() + Math.min(speedLimit, Math.max(e.delta[1], -speedLimit)) });
        //console.log(e.delta[1]);
      },
      onDrag: (e) => {
        if(e.delta[1] == 0) return;
        
        // const dir = e.direction[1];
        setProps({ pos: pos.getValue() + e.delta[1] * -1 });
      },
    },
    { domTarget: window }
  );

  useEffect(() => {
    bind();
  }, [bind]);

  // forward key
  useHotkeys(
    "up, w",
    () => {
      setProps({ pos: pos.getValue() + 2 });
    },
    []
  );

  useHotkeys(
    "down, s",
    () => {
      setProps({ pos: pos.getValue() + -1.5 });
    },
    []
  );

  // useEffect(() => {

  //   var x = percentage;
  //   console.log("ran");
  //   console.log(x);

  //   if (x > 0.2 && x < 0.25) {
  //     setProps({ pos: 0 });
  //     console.log("stop");
  //   }
  // }, [percentage]);

  // const log = (e: KeyboardEvent): void => {
  //   console.log(e.key);
  // };

  useEffect(() => {
    if(!camera || !isMobile) return;

    function onDeviceMotion(e: DeviceMotionEvent) {
      if(e.rotationRate == null || e.rotationRate.beta === null || e.rotationRate.alpha === null) return;

      if(Math.abs(e.rotationRate.alpha) < 2 && Math.abs(e.rotationRate.beta) < 2) return;

      setProps({
        rotX: rotX.getValue() + e.rotationRate.alpha * (Math.PI / 180),
        rotZ: rotZ.getValue() + e.rotationRate.beta * (Math.PI / 180)
      });
    }
    
    window.addEventListener('devicemotion', onDeviceMotion);

    return () => window.removeEventListener('devicemotion', onDeviceMotion);
  }, [ camera ]);

  // Update it every frame
  useFrame(({ mouse }) => {
    if (pos) {
      moveCamera(pos.getValue() as number);
    }

    rotateCamera(mouse);

    camera.updateMatrixWorld();
  });

  return (
    <group scale={new Vector3(scale, scale, scale)}>
      <primitive object={tubular} primitive dispose={null} />
    </group>
  );
};

export default SceneCam;
