import {
  Potree,
  PointCloudOctree,
  PointShape,
  PointSizeType,
} from "@pix4d/three-potree-loader";
import React, { useRef, useEffect } from "react";
import { useThree, useFrame, GroupProps } from "react-three-fiber";
import { isMobile, isBrowser } from "react-device-detect";
import {
  ControlOptions,
  // ControlOptionsBase,
  // ControlOptionsNumber,
  useControl,
} from "react-three-gui";
import { Group } from "three";

const potree = new Potree();

if (isBrowser) {
  potree.pointBudget = 2_000_000;
}

if (isMobile) {
  potree.pointBudget = 200_000;
}

const Cloud: React.FC<GroupProps & { url: string }> = ({ url, ...props }) => {
  const pointClouds = useRef<PointCloudOctree[]>([]);
  const groupRef = useRef<Group>();
  const { camera, gl } = useThree();

  const common = (value = 0): ControlOptions => ({
    group: "Cloud",
    type: "number",
    value,
    scrub: true,
  });

  const posX = useControl("X", common(1.5));
  const posY = useControl("Y", common(-0.5));
  const posZ = useControl("Z", common(0));
  const rotX = useControl("RotX", common(0));
  const rotY = useControl("RotY", common(0));
  const rotZ = useControl("RotZ", common(-1.5));

  useFrame(() => {
    if (pointClouds.current) {
      potree.updatePointClouds(
        pointClouds.current,
        camera as THREE.PerspectiveCamera,
        gl
      );
    }
  });

  useEffect(() => {
    potree
      .loadPointCloud(
        // The name of the point cloud which is to be loaded.
        "cloud.js",
        // Given the relative URL of a file, should return a full URL (e.g. signed).
        (relativeUrl) => `${url}${relativeUrl}`
      )
      .then((pco) => {
        if (groupRef.current) {
          pointClouds.current.push(pco);
          groupRef.current.add(pco);
          pco.material.size = 0.0225;
          pco.material.shape = PointShape.CIRCLE;
          pco.material.pointSizeType = PointSizeType.ATTENUATED;
        }
      });
  });

  return (
    <group
      ref={groupRef}
      position={[posX, posY, posZ]}
      rotation={[rotX, rotY, rotZ]}
      {...props}
    />
  );
};

export default Cloud;
