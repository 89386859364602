import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion as m } from "framer-motion";

import { ReactComponent as ScrollSVG } from "../assets/scroll.svg";
import { ReactComponent as TurnSVG } from "../assets/turn.svg";
import { ReactComponent as SpeakerSVG } from "../assets/speaker.svg";
// import { ReactComponent as MuteSVG } from "../assets/mute.svg";
// import { ReactComponent as BatterySVG } from "../assets/battery.svg";
import { ReactComponent as InfoSVG } from "../assets/info.svg";
import { SetUpdateFn } from "react-spring";
import { isMobile, isBrowser } from "react-device-detect";

const containerAnimations = {
  initial: "show",
  variants: {
    show: {
      opacity: 1,
      x: "-50%",
      y: `calc(-50% - 0px)`,
    },
    hide: {
      opacity: 0,
      x: "-50%",
      y: `calc(-50% - 50px)`,
    },
  },
};
const textAnimations = {
  initial: "hide",
  exit: "exit",
  variants: {
    show: ({ i = 0, d = 0 }) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 2,
        delay: i * 0.2 + d,
        ease: "easeOut",
      },
    }),
    hide: {
      opacity: 0,
      y: 75,
    },
    exit: ({ i = 0, d = 0 }) => ({
      opacity: 0,
      y: -75,
      transition: {
        duration: 2,
        delay: i * 0.2,
        ease: "easeIn",
      },
    }),
  },
};

const Overlay: React.FC<{
  handleDiscover: () => void;
  setProps: SetUpdateFn<any>;
  sceneCamRef: React.MutableRefObject<number>;
}> = ({ handleDiscover, setProps, sceneCamRef }) => {
  const [step, setStep] = useState(1);

  const handleClickDiscover = useCallback(() => {
    handleDiscover();
    setStep(2);
    // setProps({ pos: 11 });

    // setTimeout(() => {
    //   setProps({ pos: 0 });
    // }, 6000);
    // eslint-disable-next-line
  }, []);

  const handleClickExplore = useCallback(() => {
    setStep(3);
    // eslint-disable-next-line
  }, []);

  const handleClickInfo = useCallback(() => {
    if (isMobile) {
      setStep(44);
    }
    if (isBrowser) {
      setStep(4);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sceneCamRef.current > 0.87) {
        setStep(5);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const Steps = useMemo(() => {
    switch (step) {
      case 1: {
        return (
          <CenterText key="discover" {...containerAnimations} animate="show">
            <m.h1 custom={{ i: 0, d: 0 }} {...textAnimations} animate="show">
              Truth or Consequences &nbsp;|&nbsp; An Artifact
            </m.h1>
            <m.p custom={{ i: 1, d: 0 }} {...textAnimations} animate="show">
              Where do the pasts live? Do they live in the places, objects,
              sounds, stories, in the fractured memories around us? Are they
              ready to transmit if we're ready to listen?
            </m.p>
            <br />
            <br />
            <m.p custom={{ i: 2, d: 0 }} {...textAnimations} animate="show">
              Welcome to a space where past, present, and future intertwine.
            </m.p>
            <m.p custom={{ i: 3, d: 0 }} {...textAnimations} animate="show">
              Welcome to an expanded world of the speculative documentary, Truth
              or Consequences.
            </m.p>
            <br />
            <br />
            <m.button
              custom={{ i: 4, d: 0 }}
              {...textAnimations}
              animate="show"
              onClick={handleClickDiscover}
            >
              discover &gt;
            </m.button>
          </CenterText>
        );
      }
      case 2: {
        return (
          <CenterText key="explore" {...containerAnimations}>
            <m.h1 animate="show" custom={{ i: 0, d: 2.2 }} {...textAnimations}>
              Navigation
            </m.h1>
            <ul>
              {isMobile ?
                <m.li
                  animate="show"
                  custom={{ i: 1, d: 2.2 }}
                  {...textAnimations}
                >
                  <span>
                    <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                  </span>
                  Swipe to Explore (Forward and Back)
                </m.li>
              :
                <>
                  <m.li
                    animate="show"
                    custom={{ i: 1, d: 2.2 }}
                    {...textAnimations}
                  >
                    <span>
                      <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                    </span>
                    Scroll to Explore (Forward and Back)
                  </m.li>
                  <m.li
                    animate="show"
                    custom={{ i: 2, d: 2.2 }}
                    {...textAnimations}
                  >
                    <span>
                      <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                    </span>
                    Use Up, Down or "W", "S" (Forward and Back)
                  </m.li>
                  <m.li
                    animate="show"
                    custom={{ i: 3, d: 2.2 }}
                    {...textAnimations}
                  >
                    <span>
                      <TurnSVG style={{ transform: "translateY(-4px)" }} />
                    </span>
                    Move Cursor to Look Around
                  </m.li>
                </>
              }
              {/* <m.li
                animate="show"
                custom={{ i: 3, d: 2.2 }}
                {...textAnimations}
              >
                <span>
                  <BatterySVG style={{ transform: "translateY(-2px)" }} />
                </span>
                Disable Low Power Mode (for Mobile)
              </m.li> */}
              <m.li
                animate="show"
                custom={{ i: 3, d: 2.2 }}
                {...textAnimations}
              >
                <span>
                  <InfoSVG
                    style={{
                      transform: "translate(1px, -6px)",
                      width: 18,
                      height: 18,
                    }}
                  />
                </span>
                Curious about the World?{" "}
                <strong className="link" onClick={handleClickInfo}>
                  Click here
                </strong>
              </m.li>
            </ul>
            <m.button
              animate="show"
              custom={{ i: 4, d: 2.2 }}
              {...textAnimations}
              onClick={handleClickExplore}
            >
              explore &gt;
            </m.button>
          </CenterText>
        );
      }
      case 3:
        return <></>;
      case 4: {
        return (
          <CenterText key="about" {...containerAnimations}>
            <m.h1 custom={{ i: 0, d: 0 }} {...textAnimations} animate="show">
              The Film &nbsp;|&nbsp; Truth or Consequences
            </m.h1>
            <m.p custom={{ i: 1, d: 0 }} {...textAnimations} animate="show">
              <a
                href="http://www.torcfilm.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em>Truth or Consequences</em>
              </a>{" "}
              is a speculative documentary that takes place in the desert town
              of Truth or Consequences, New Mexico. Subtly set in a near future
              when commercial space travel has begun, the film visits with five
              people in the town.
            </m.p>
            <br />
            <br />
            <m.h1 custom={{ i: 2, d: 0 }} {...textAnimations} animate="show">
              This Artifact &nbsp;|&nbsp; Artesian Trailer Park
            </m.h1>
            <m.p custom={{ i: 3, d: 0 }} {...textAnimations} animate="show">
              You are exploring Artesian Trailer Park, Philip's home. This space
              blends virtual worlds reconstructed from documentary footage,
              documentary scenes, studio sessions of Bill Frisell creating the
              improvised score, archival footage from the area, and audio
              captured in the town.
            </m.p>
            <br />
            <br />
            <m.h1 animate="show" custom={{ i: 4, d: 0 }} {...textAnimations}>
              Navigation
            </m.h1>
            <ul>
              <m.li animate="show" custom={{ i: 5, d: 0 }} {...textAnimations}>
                <span>
                  <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                </span>
                Scroll to Explore (Forward and Back)
              </m.li>
              <m.li animate="show" custom={{ i: 6, d: 0 }} {...textAnimations}>
                <span>
                  <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                </span>
                Use Up, Down or "W", "S" (Forward and Back)
              </m.li>
              <m.li animate="show" custom={{ i: 7, d: 0 }} {...textAnimations}>
                <span>
                  <TurnSVG style={{ transform: "translateY(-4px)" }} />
                </span>
                Move Cursor to Look Around
              </m.li>
            </ul>
            <br />
            <br />
            <m.p custom={{ i: 8, d: 0 }} {...textAnimations} animate="show">
              Made with ♡ by fellow future archivists:
            </m.p>
            <m.p custom={{ i: 9, d: 0 }} {...textAnimations} animate="show">
              {" "}
              <a
                href="http://hannahjayanti.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hannah Jayanti
              </a>{" "}
              *{" "}
              <a
                href="https://alexanderporter.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Alexander Porter
              </a>{" "}
              *{" "}
              <a
                href="http://www.paikowsky.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adam Paikowsky
              </a>{" "}
              *{" "}
              <a
                href="https://github.com/artokun"
                target="_blank"
                rel="noopener noreferrer"
              >
                Arthur Longbottom
              </a>{" "}
              <br />{" "}
              <a
                href="http://www.clairesophie.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Claire Hentschker
              </a>{" "}
              *{" "}
              <a
                href="http://vermontdigitalarts.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Elliott Mitchell
              </a>{" "}
              *{" "}
              <a
                href="https://www.sentientartfilm.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sentient.Art.Film
              </a>{" "}
              *{" "}
              <a
                href="https://dotdotdash.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                dotdotdash
              </a>{" "}
            </m.p>
            {/* <ul style={{ marginTop: 50 }}>
              <m.li animate="show" custom={{ i: 3, d: 0 }} {...textAnimations}>
                <span>
                  <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                </span>
                Scroll to Explore (Forward and Backward)
              </m.li>
              <m.li animate="show" custom={{ i: 4, d: 0 }} {...textAnimations}>
                <span>
                  <TurnSVG style={{ transform: "translateY(-4px)" }} />
                </span>
                Move Cursor to Look Around
              </m.li>
            </ul> */}
            <m.button
              animate="show"
              custom={{ i: 10, d: 0 }}
              {...textAnimations}
              onClick={() => setStep(3)}
            >
              return &gt;
            </m.button>
          </CenterText>
        );
      }
      case 44: {
        return (
          <CenterText key="about" {...containerAnimations}>
            <m.h1 custom={{ i: 0, d: 0 }} {...textAnimations} animate="show">
              The Film &nbsp;|&nbsp; Truth or Consequences
            </m.h1>
            <m.p custom={{ i: 1, d: 0 }} {...textAnimations} animate="show">
              <a
                href="http://www.torcfilm.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em>Truth or Consequences</em>
              </a>{" "}
              is a speculative documentary that takes place in the desert town
              of Truth or Consequences, New Mexico. Subtly set in a near future
              when commercial space travel has begun, the film visits with five
              people in the town.
            </m.p>
            <br />
            <br />
            {/* <m.h1 custom={{ i: 2, d: 0 }} {...textAnimations} animate="show">
              This Artifact &nbsp;|&nbsp; Artesian Trailer Park
            </m.h1>
            <m.p custom={{ i: 3, d: 0 }} {...textAnimations} animate="show">
              You are exploring Artesian Trailer Park, Philip's home. This space
              blends virtual worlds reconstructed from documentary footage,
              documentary scenes, studio sessions of Bill Frisell creating the
              improvised score, archival footage from the area, and audio
              captured in the town.
            </m.p>
            <br />
            <br /> */}
            <m.h1 animate="show" custom={{ i: 2, d: 0 }} {...textAnimations}>
              Navigation
            </m.h1>
            <ul>
              <m.li animate="show" custom={{ i: 3, d: 0 }} {...textAnimations}>
                <span>
                  <ScrollSVG style={{ transform: "translateY(-2px)" }} />
                </span>
                Scroll to Explore (Forward and Back)
              </m.li>
              {/* <m.li
                animate="show"
                custom={{ i: 6, d: 2.2 }}
                {...textAnimations}
              >
                <span>
                  <TurnSVG style={{ transform: "translateY(-4px)" }} />
                </span>
                Move Cursor to Look Around
              </m.li> */}
            </ul>
            {/* <br />
            <br />
            <m.p custom={{ i: 8, d: 0 }} {...textAnimations} animate="show">
              Made with ♡ by fellow future archivists:
            </m.p>
            <m.p custom={{ i: 9, d: 0 }} {...textAnimations} animate="show">
              {" "}
              <a
                href="http://hannahjayanti.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hannah Jayanti
              </a>{" "}
              *{" "}
              <a
                href="https://alexanderporter.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Alexander Porter
              </a>{" "}
              *{" "}
              <a
                href="http://www.paikowsky.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adam Paikowsky
              </a>{" "}
              *{" "}
              <a
                href="https://github.com/artokun"
                target="_blank"
                rel="noopener noreferrer"
              >
                Arthur Longbottom
              </a>{" "}
              <br />{" "}
              <a
                href="http://www.clairesophie.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Claire Hentschker
              </a>{" "}
              *{" "}
              <a
                href="http://vermontdigitalarts.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Elliott Mitchell
              </a>{" "}
              *{" "}
              <a
                href="https://www.sentientartfilm.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sentient.Art.Film
              </a>{" "}
              *{" "}
              <a
                href="https://dotdotdash.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                dotdotdash
              </a>{" "}
            </m.p> */}
            <br />
            <br />
            <m.button
              animate="show"
              custom={{ i: 2, d: 0 }}
              {...textAnimations}
              onClick={() => setStep(3)}
            >
              return &gt;
            </m.button>
          </CenterText>
        );
      }
      case 5: {
        return (
          <CenterText key="about" {...containerAnimations}>
            <m.p custom={{ i: 1, d: 0 }} {...textAnimations} animate="show">
              <a href="/">Revisit This Experience</a>
            </m.p>
            <m.p custom={{ i: 2, d: 0 }} {...textAnimations} animate="show">
              <a
                href="http://www.torcfilm.com/about"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn About The Film
              </a>
            </m.p>
            <m.p custom={{ i: 3, d: 0 }} {...textAnimations} animate="show">
              <a
                href="https://www.sentientartfilm.com/truth-or-consequences"
                target="_blank"
                rel="noopener noreferrer"
              >
                Find Screenings of The Film In Your Area
              </a>
            </m.p>
            {/* <m.button
              animate="show"
              custom={{ i: 4, d: 0 }}
              {...textAnimations}
              onClick={() => setStep(3)}
            >
              return &gt;
            </m.button> */}
          </CenterText>
        );
      }
    }
    // eslint-disable-next-line
  }, [step]);

  return (
    <Wrapper>
      <AnimatePresence>
        {step !== 3 ? (
          <>
            <Background
              initial={{ opacity: 1 }}
              animate={{
                opacity: step === 1 ? 1 : step === 3 ? 0 : 0.8,
              }}
              exit={{ opacity: 0 }}
            />
            <Gradient
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          </>
        ) : (
          <>
            <InfoButton
              onClick={handleClickInfo}
              // onClick={() => console.log(sceneCamRef.current)}
            >
              <InfoSVG />
            </InfoButton>
            <ToggleSoundButton
              onClick={() => {
                handleDiscover();
                console.log("hi");
              }}
            >
              <SpeakerSVG />
            </ToggleSoundButton>
          </>
        )}
      </AnimatePresence>
      <div style={{ pointerEvents: step === 3 ? "none" : "all" }}>
        <AnimatePresence>{Steps}</AnimatePresence>
      </div>
    </Wrapper>
  );
};

const InfoButton = styled.button`
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  bottom: 50px;
  right: 50px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
`;

const ToggleSoundButton = styled(InfoButton)`
  bottom: unset;
  top: 50px;

  svg {
    width: 34px;
    height: 32px;
  }
`;

const Wrapper = styled.div``;

const Background = styled(m.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
`;

const Gradient = styled(m.div)`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
      0% 100% at 0% 50%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.08);
`;

const CenterText = styled(m.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  font-family: "FreightSans Pro";
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-size: 36px;
    font-weight: normal;
    margin: 0 0 20px;
    pointer-events: none;
    letter-spacing: 2px;
  }

  p {
    font-size: 15px;
    text-align: center;
    line-height: 2;
    width: 100%;
    max-width: 640px;
    min-width: 300px;
    margin: 0;
    letter-spacing: 1px;
  }

  button {
    margin: 30px 40px 0 0;
    align-self: flex-end;
    appearance: none;
    background: none;
    color: #dadada;
    font-family: "FreightSans Pro";
    border: none;
    cursor: pointer;
    transition: color 0.1s ease;

    &:hover {
      font-weight: bold;
      color: white;
    }

    &:focus {
      outline: none;
    }
  }

  a,
  .link {
    color: #dadada;
    font-family: "FreightSans Pro";

    &:hover {
      cursor: pointer;
      font-weight: bold;
      color: white;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 0;
    margin: 0;
    position: relative;
    padding-left: 50px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    font-size: 14px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Overlay;
