import { Stars } from "@react-three/drei";
import React from "react";
import { isMobile } from "react-device-detect";

const Starscape: React.FC = () => {
  return (
    <Stars
      radius={100}
      depth={50}
      count={isMobile ? 2500 : 5000}
      factor={4}
      saturation={0}
      fade
    />
  );
};

export default Starscape;
